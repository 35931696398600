<template>


    <div class="container-pc Global-W">

        <div class="find-title">Retrieve password</div>



        <div class="steps-view">

            <el-steps :active="active" finish-status="success">
                <el-step title="Email address"></el-step>
                <el-step title="Verify identity"></el-step>
                <el-step title="Set new password"></el-step>
                <el-step title="Finish"></el-step>
            </el-steps>
        </div>
        <div class="login-from">

            <div class="login-input-item" v-if="active == 1">
                <div>*{{ $t('Login.Email') }}</div>
                <el-input v-model="registerData.email" class="login-input" clearable placeholder="Email address" />
            </div>

            <div class="login-input-item" v-if="active == 1">
                <div>*Code</div>
                <div class="login-input-item-view">
                    <el-input v-model="registerData.imgcode" class="login-input" clearable
                        placeholder="Please enter the graphic verification code" />
                    <div class="login-code CURSOR" @click="reshCode">
                        <img :src="imgCodeSrc" alt="">
                    </div>
                </div>
            </div>


            <div class="login-input-item" v-if="active == 2">
                <div>*Code</div>
                <div class="login-input-item-view">
                    <el-input v-model="registerData.smscode" class="login-input" clearable max-length="4" />
                    <div class="login-code CURSOR" @click="handleGetEmailCode">{{ codeValue }}</div>
                </div>
            </div>

            <div class="login-input-item " v-if="active == 3">
                <div>*new password</div>
                <el-input v-model="registerData.password" class="login-input" clearable
                    placeholder="Please enter a new password" type="password" />
            </div>
            <div class="login-input-item " v-if="active == 3">
                <div>*Confirm password again</div>
                <el-input v-model="registerData.password" class="login-input" clearable
                    placeholder="Confirm password again" type="password" />
            </div>


            <div v-if="active == 4">
                <div>New password set successfully!</div>
                <div>Please remember your new password. <span @click="handleLoginOut" class="login CURSOR">Click
                        "return" to login</span> </div>
            </div>

            <div class="login-sign-view">
                <van-button class="login-sign" block type="default" native-type="submit"
                    @click="handleOnchangeactive('Last')" v-if="active == 2 || active == 3">Previous</van-button>
                <van-button class="login-sign" block type="primary" color="#414141" native-type="submit"
                    @click="handleOnchangeactive('Next')" :loading="isLoading" v-if="active == 1 || active == 2">Next
                    step</van-button>
                <van-button class="login-sign" block type="primary" color="#414141" native-type="submit"
                    @click="resetPass" v-if="active == 3">Submit</van-button>
            </div>

        </div>
    </div>



    <div class="container-m">

        <div class="find-title">Retrieve password</div>



        <div class="steps-view">

            <el-steps :active="active" finish-status="success">
                <el-step title="Email address"></el-step>
                <el-step title="Verify identity"></el-step>
                <el-step title="Set new password"></el-step>
                <el-step title="Finish"></el-step>
            </el-steps>
        </div>
        <div class="login-from">

            <div class="login-input-item" v-if="active == 1">
                <div>*{{ $t('Login.Email') }}</div>
                <el-input v-model="registerData.email" class="login-input" clearable />
            </div>

            <div class="login-input-item" v-if="active == 1">
                <div>*Code</div>
                <div class="login-input-item-view">
                    <el-input v-model="registerData.imgcode" class="login-input" clearable
                        placeholder="Please enter the graphic verification code" />
                    <div class="login-code CURSOR" @click="reshCode">
                        <img :src="imgCodeSrc" alt="">
                    </div>
                </div>
            </div>


            <div class="login-input-item" v-if="active == 2">
                <div>*Email Code</div>
                <div class="login-input-item-view">
                    <el-input v-model="registerData.smscode" class="login-input" clearable max-length="4" />
                    <div class="login-code CURSOR" @click="handleGetEmailCode">{{ codeValue }}</div>
                </div>
            </div>

            <div class="login-input-item " v-if="active == 3">
                <div>*new password</div>
                <el-input v-model="registerData.password" class="login-input" clearable
                    placeholder="Please enter a new password" type="password" />
            </div>
            <div class="login-input-item " v-if="active == 3">
                <div>*Confirm password again</div>
                <el-input v-model="registerData.password" class="login-input" clearable
                    placeholder="Confirm password again" type="password" />
            </div>


            <div v-if="active == 4">
                <div>New password set successfully!</div>
                <div>Please remember your new password. <span @click="handleLoginOut" class="login CURSOR">Click
                        "return" to login</span> </div>
            </div>

            <div class="login-sign-view">
                <van-button class="login-sign" block type="default" native-type="submit"
                    @click="handleOnchangeactive('Last')" v-if="active == 2 || active == 3">上一步</van-button>
                <van-button class="login-sign" block type="primary" color="#414141" native-type="submit"
                    @click="handleOnchangeactive('Next')" :loading="isLoading"
                    v-if="active == 1 || active == 2">下一步</van-button>
                <van-button class="login-sign" block type="primary" color="#414141" native-type="submit"
                    @click="resetPass" v-if="active == 3">Submit</van-button>
            </div>

        </div>
    </div>

</template>



<script name="FindPwd" setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from "vue-router";

import config from '@/apis/config'
import requestAsync from "../../utils/request";
import { ElMessage } from 'element-plus'
import { useStore } from "vuex";
import Cookies from 'js-cookie'
import { fa } from 'element-plus/es/locale';

const store = useStore()
const route = useRoute()
const router = useRouter()

const active = ref(1)
const isLoading = ref(false)
const imgCodeSrc = ref('')
const registerData = ref({
    email: '',
    loginBy: 'MAIL',
    imgcode: '',
    codeValue: '',
    smscode: '',
    password: ''
})
let codeValue = ref('Send the verification code')

onMounted(() => {
    reshCode()
})


const reshCode = () => {
    imgCodeSrc.value = `https://api.roseonly.com/user/getVerify?t=` + Math.random()
}

const checkUserNam = async () => {
    const data = await requestAsync({
        url: "/user/checkUserName",
        data: {
            // prefix: '',
            // mobile: '',
            loginBy: 'MAIL',
            email: registerData.value.email,
            imgcode: registerData.value.imgcode
        }
    });
    console.log('checkUserNam', data)
    if (data.code == 200) {
        active.value++
    } else {
        ElMessage.error(data.msg)
    }
    isLoading.value = false
}


const handleGetEmailCode = async () => {
    if (codeValue.value != 'Send the verification code') {
        return
    }
    const data = await requestAsync({
        url: "/user/sendMail",
        data: {
            mail: registerData.value.email,
            code: registerData.value.imgcode
        }
    });
    if (data.code == 200) {
        ElMessage({
            showClose: true,
            message: data.msg,
            type: 'success',
        })

        let _s = 60
        const timer = setInterval(() => {
            if (_s === 1) {
                clearInterval(timer)
                codeValue.value = 'Send the verification code'
            } else {
                _s--
                codeValue.value = `Valid within (${_s}) seconds`
            }
        }, 1000)



    } else {
        ElMessage.error(data.msg)
    }
}


const resetPass = async () => {
    const data = await requestAsync({
        url: "/user/resetPass",
        data: {
            email: registerData.value.email,
            smscode: registerData.value.smscode,
            password: registerData.value.password,
            loginBy: 'MAIL',
        }
    });
    if (data.code == 200) {
        active.value++
    } else {
        ElMessage.error('The SMS verification code is incorrect. Please re-enter it')
    }
    isLoading.value = false
}




const handleOnchangeactive = (type) => {
    if (isLoading.value) return
    isLoading.value = true
    if (type == 'Last') {
        active.value--
        isLoading.value = false

        return
    }
    if (active.value == 1) {
        checkUserNam()
    }


    if (active.value == 2) {

        if (registerData.value.smscode) {
            active.value++
            isLoading.value = false
        } else {
            ElMessage.error('Please fill in the text verification code')
            isLoading.value = false
        }
    }
}

const handleLoginOut = () => {
    Cookies.remove('uid')
    Cookies.remove('token')
    store.dispatch('login', false)
    router.replace({
        name: 'Login'
    })
}

</script>


<style lang="scss" scoped>
.container-pc {

    .find-title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        text-align: center;
    }

    .steps-view {
        width: 50%;
        margin: 50px auto;
    }

    .login-from {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin: 50px auto;

        .login-input-item {
            width: 50%;
            height: 75px;
            margin-bottom: 10px;

            .login-input {
                height: 50px;
            }

            .login-input-item-view {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .login-code {
                    width: 300px;
                    height: 50px;
                    line-height: 50px;
                    margin-left: 20px;
                    font-size: 12px;
                }
            }
        }

        .login-sign-view {
            display: flex;
            justify-content: center;
            width: 100%;

            .login-sign {
                width: 25%;
                height: 50px;
                line-height: 50px;
                margin: 40px 10px;
            }
        }

        .login {
            height: 25px;
            line-height: 25px;
            border-bottom: 1px solid #414141;
            margin: 5px 0;
        }

    }
}



.container-m {
    font-size: .25rem;
}
</style>